import React from "react";
import FooterTwo from "../Components/Footer/FooterTwo";
import HeaderOne from "../Components/Header/HeaderOne";
// import bannerBg from "../assets/img/page-banner.jpg";
import PageBanner from "../Components/PageBanner";
import BrandCarouselThree from "../Components/Brands/BrandCarouselThree";
import Pricing from "../Components/Price/Pricing";
import TestimonialOne from "../Components/Testimonial/TestimonialOne";
const bannerBg =
  "https://imageio.forbes.com/blogs-images/gabrielshaoolian/files/2018/08/hero-image-1200x675.jpg?format=jpg&width=960";
const PricingPage = () => {
  return (
    <>
      <PageBanner title="Plan Price" bannerBg={bannerBg} currentPage="price" />
      <Pricing />
      <TestimonialOne />
      {/* <BrandCarouselThree /> */}
    </>
  );
};

export default PricingPage;
