import React, { useEffect } from "react";
import FooterTwo from "../Components/Footer/FooterTwo";
import HeaderOne from "../Components/Header/HeaderOne";
// import bannerBg from "../assets/img/page-banner.jpg";
import PageBanner from "../Components/PageBanner";
import BrandCarouselThree from "../Components/Brands/BrandCarouselThree";
import ContactPageContents from "../Components/Contact/ContactPageContents";
const bannerBg =
  "https://img.freepik.com/premium-photo/man-showing-contact-us-message-paper_220873-12105.jpg";
const ContactPage = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  return (
    <>
      <PageBanner
        title="Contact Us"
        bannerBg={bannerBg}
        currentPage="contact"
      />
      <ContactPageContents />
      {/* <BrandCarouselThree /> */}
    </>
  );
};

export default ContactPage;
