import React from "react";
import FooterTwo from "../Components/Footer/FooterTwo";
import HeaderOne from "../Components/Header/HeaderOne";
// import bannerBg from "../assets/img/page-banner.jpg";
import PageBanner from "../Components/PageBanner";
import TeamOne from "../Components/Team/TeamOne";
import TeamTwo from "../Components/Team/TeamTwo";
const bannerBg =
  "https://www.add-on.com/wp-content/uploads/2022/05/BLOG__The-Personal-and-Business-Related-Benefits-of-Returning-to-the-Office-_-1536x883.jpg.webp";
const TeamPage = () => {
  return (
    <>
      <PageBanner title="Our Team" bannerBg={bannerBg} currentPage="Team" />
      <TeamTwo />
      <TeamOne />
    </>
  );
};

export default TeamPage;
