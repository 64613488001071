import React, { useEffect } from "react";
import FooterTwo from "../Components/Footer/FooterTwo";
import HeaderOne from "../Components/Header/HeaderOne";
// import bannerBg from "../assets/img/page-banner.jpg";
import PageBanner from "../Components/PageBanner";
import InvestorFormContents from "../Components/Investor/InvestorFormContents";
const bannerBg =
    "https://www.freepik.com/free-vector/investment-concept-illustration-idea-money-finance-capital_26477400.htm#query=investers%20background&position=17&from_view=search&track=ais";

const InvestorRelation = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <PageBanner
                title="INVESTOR RELATION"
                bannerBg={bannerBg}
                currentPage="investor"
            />
            <InvestorFormContents />
            {/* <BrandCarouselThree /> */}
        </>
    )
}

export default InvestorRelation;