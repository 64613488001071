import React, { useEffect, useState } from "react";
import FaqOneVideo from "./FaqOneVideo";
import CountUp from "react-countup";
// import faqOneData from "./faqOneData";
import { getFAQs, getStats } from "../../utils";

const FaqOne = () => {
  const [FAQsData, setFAQsData] = useState("");
  const [statsData, setStatsData] = useState("");

  useEffect(() => {
    const fetchFAQs = async () => {
      const res = await getFAQs();
      setFAQsData(res.data);
    };
    fetchFAQs();
  }, []);

  useEffect(() => {
    const fetchStats = async () => {
      const res = await getStats();
      setStatsData(res.data);
    };
    fetchStats();
  }, []);
  return (
    <section className="faq-funfact-section section-padding">
      <div className="container">
        <div className="fun-fact-wrapper text-white mtm-30 text-center">
          {<div className="row">
            {statsData.length > 0 &&
              statsData.map((item) => {
                return (
                  <div className="col-lg-3 col-md-6 col-12" key={item.id}>
                    <div className="single-fun-fact mb-4 mb-lg-0">
                      <h2>
                        <CountUp
                          end={item.attributes.quantity}
                          duration={3}
                        ></CountUp>
                        {item.attributes.field === "projects"
                          ? "K+"
                          : item.attributes.field === "countries covered"
                          ? "%"
                          : "+"}
                      </h2>
                      <h3>{item.attributes.field}</h3>
                    </div>
                  </div>
                );
              })}
          </div>}
        </div>
        <div className="faq-wrapper section-padding pb-0">
          <div className="row equal">
            <div className="col-lg-6 col-12 col-xl-7 pe-lg-0">
              <div className="faq-content">
                <div className="faq-accordion">
                  <div className="accordion" id="accordion">
                    {FAQsData.length > 0 &&
                      FAQsData.map((item, index) => (
                        <div className="accordion-item" key={item.id}>
                          <h4 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#faq${index.toString()}`}
                              aria-expanded="false"
                              aria-controls={index.toString()}
                            >
                              {item.attributes.question}
                            </button>
                          </h4>
                          <div
                            id={`faq${index.toString()}`}
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordion"
                          >
                            <div className="accordion-body">
                              {item.attributes.answer}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 col-12 ps-lg-0">
              <FaqOneVideo />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqOne;
