import { useEffect, useState } from "react";
import workProcessData from "./workProcessData";
import { getHomeNext } from "../../utils";
import { Link } from "react-router-dom";
import HeaderOne from "../Header/HeaderOne";

const WorkProcess = () => {
    const [workData, setWorkData] = useState(workProcessData)
    useEffect(() => {
        getData()
    }, [])
    const getData = async () => {
        const response = await getHomeNext()
        setWorkData(response.data)
    }
    const products = [
        {
            "name": "product1",
            "content": "Our promise as a contractor is to build community value into every projects while delivering professional",
            "img": `url(${"https://cdn.pixabay.com/photo/2015/01/09/11/08/startup-594090__340.jpg"})`,

        },
        {
            "name": "product2",
            "content": "Our promise as a contractor is to build community value into every projects while delivering professional",
            "img": `url(${"https://cdn.pixabay.com/photo/2015/01/09/11/08/startup-594090__340.jpg"})`
        },
        {
            "name": "product3",
            "content": "Our promise as a contractor is to build community value into every projects while delivering professional",
            "img": `url(${"https://cdn.pixabay.com/photo/2015/01/09/11/08/startup-594090__340.jpg"})`
        },
        {
            "name": "product4",
            "content": "Our promise as a contractor is to build community value into every projects while delivering professional",
            "img": `url(${"https://cdn.pixabay.com/photo/2015/01/09/11/08/startup-594090__340.jpg"})`
        }
    ]
    return (
        <section className="workp-process-section section-padding mtm-30" style={{ background: "#f5f5f5" }}>
            <div className="container ">
                <div className="row margin-solutions">
                    <h1 className="text-center">Platforms</h1>
                    {
                        products.map((data, index) => (
                            <div className="col-md-6 col-12 col-lg-4 mt-30" >
                                <div className="single-service-box style-1">
                                    <div
                                        className="service-bg bg-cover"
                                        style={{
                                            backgroundImage: data.img,
                                        }}
                                    ></div>
                                    <div className="icon">
                                        <i className={"flaticon-blueprint"}></i>
                                    </div>
                                    <div className="contents">
                                        <a href="#about-one" >
                                            <h4>
                                                {data.name}
                                            </h4>
                                            <p >{data.content}</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

export default WorkProcess;