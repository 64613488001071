import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { getContacts, postContactDetail } from "../../utils";

function RequestDemo() {
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [contactData, setContactData] = useState("");
  const [details, setDetails] = useState({
    organization_name: "",
    organization_type: "",
    contact_person: "",
    salutation: "",
    position: "",
    street_address: "",
    street_address_1: "",
    city: "",
    country: "",
    zip_code: "",
    country: "",
    phone: "",
    email: "",
    website: "",
    purpose: "",
    contact_means: "",
    contact_time: "",
    networks: ""
  })
  const handleSubmit = async (e) => {
    e.preventDefault()
    const response = await postContactDetail({ data: details })
    alert("Details Submitted")
    setDetails({
      organization_name: "",
      organization_type: "",
      contact_person: "",
      salutation: "",
      position: "",
      street_address: "",
      street_address_1: "",
      city: "",
      country: "",
      zip_code: "",
      country: "",
      phone: "",
      email: "",
      website: "",
      purpose: "",
      contact_means: "",
      contact_time: ""
    })
  }
  const handleChange = (e) => {
    let data = { ...details };
    data[e.target.name] = e.target.value
    setDetails(data)
  }

  return (
    <>
      <Button className="btn bg-warning text-white p-3 ms-3 rounded mt-3" onClick={handleShow}>
        Request demo
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        backdrop="static"
        keyboard={false}
        centered
        style={{ backgroundColor: "rgb(0 0 0 / 57%)" }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Request Demo</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{backgroundColor:"#EDEDED"}}>
            <div className="col-lg-12 col-12">
              <div className="contact-form" style={{ background: "#EDEDED" }}>
                <form
                  className="row"
                  onSubmit={(e) => { handleSubmit(e) }}
                >
                  <div className="col-md-6 col-12">
                    <div className="single-personal-info">
                      <input type="text" name="organization_name" placeholder="Organization Name" value={details.organization_name} onChange={(e) => handleChange(e)} />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div>
                      <Form.Select size="lg" name="organization_type" value={details.organization_type} onChange={(e) => { handleChange(e) }} aria-label="Default select example" style={{ padding: "20px 30px", border: "none", backgroundColor: "#F8F9FC", fontSize: "17px", fontWeight: "400" }}>
                        <option value="">Organization Type</option>
                        <option value="Governmental">Governmental</option>
                        <option value="Authority">Authority</option>
                        <option value="Private">Private Company</option>
                        <option value="NGO">NGO</option>
                      </Form.Select>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div>
                      <Form.Select size="lg" name="salutation" value={details.salutation} onChange={(e) => { handleChange(e) }} aria-label="Default select example" style={{ padding: "20px 30px", border: "none", backgroundColor: "#F8F9FC", fontSize: "17px", fontWeight: "400" }}>
                        <option value="">Salutation</option>
                        <option value="mr">Mr</option>
                        <option value="mrs">Mrs</option>
                        <option value="miss">Miss</option>
                        <option value="ms">Ms</option>
                      </Form.Select>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="single-personal-info">
                      <input type="text" name="contact_person" placeholder="Contact Person" value={details.contact_person} onChange={(e) => handleChange(e)} />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="single-personal-info">
                      <input type="text" name="position" placeholder="Position" value={details.position} onChange={(e) => handleChange(e)} />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="single-personal-info">
                      <input type="text" name="website" placeholder="Business Website" value={details.website} onChange={(e) => handleChange(e)} />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="single-personal-info">
                      <input type="text" name="street_address" placeholder="Address Line 1" value={details.street_address} onChange={(e) => handleChange(e)} />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="single-personal-info">
                      <input type="text" name="street_address_1" placeholder="Address Line 2" value={details.street_address_1} onChange={(e) => handleChange(e)} />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="single-personal-info">
                      <input type="text" name="city" placeholder="City" value={details.city} onChange={(e) => handleChange(e)} />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="single-personal-info">
                      <input type="text" name="country" placeholder="Country" value={details.country} onChange={(e) => handleChange(e)} />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="single-personal-info">
                      <input type="text" name="zip_code" placeholder="Zip Code" value={details.zip_code} onChange={(e) => handleChange(e)} />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="single-personal-info">
                      <input type="text" name="phone" placeholder="Phone" value={details.phone} onChange={(e) => handleChange(e)} />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="single-personal-info">
                      <input type="text" name="email" placeholder="Business Email" value={details.email} onChange={(e) => handleChange(e)} />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="single-personal-info">
                      <Form.Select size="lg" name="purpose" value={details.purpose} onChange={(e) => { handleChange(e) }} aria-label="Default select example" style={{ padding: "20px 30px", border: "none", backgroundColor: "#F8F9FC", fontSize: "17px", fontWeight: "400" }}>
                        <option value="">Purpose</option>
                        <option value="clients">Clients Related</option>
                        <option value="Service">Service Related</option>
                        <option value="Social">Social</option>
                      </Form.Select>
                    </div>
                  </div>
                  <div className="col-md-12 col-12" style={{ marginTop: "25px" }}>
                    <button
                      className="submit-btn"
                      type="submit"
                    >
                      Submit Now
                    </button>
                  </div>
                </form>
              </div>
            </div>
        </Modal.Body>

      </Modal>
    </>
  );
}

export default RequestDemo;