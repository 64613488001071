import { Swiper, SwiperSlide } from "swiper/react";
import slide1 from "../../assets/img/home1/slide1.jpg";

import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { Link } from "react-router-dom";
import { getHomeHero } from "../../utils";
import { useEffect, useState } from "react";
import axios from "axios";
import { config } from "../../config";
const bgimg =
  "http://ctfassets.imgix.net/vh7r69kgcki3/GI6cQcDty6kUa6g6kk4K0/8fdf6a811282a3a84298d2b764a6b7ec/Web_150DPI-20221216_WeWork_Product_Shoot_Q4_8.jpeg";
const bgimg1 =
  "https://images.unsplash.com/photo-1524758631624-e2822e304c36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80";
const HeroOne = () => {

  const [heroData, setHeroData] = useState([]);

  useEffect(() => {

    fetchHero();
  }, []);

  const fetchHero = async () => {
    const res = await getHomeHero();
    setHeroData(res.data);
  };

  return (
    <section className="hero-wrapper hero-1">
      <Swiper navigation={true} modules={[Navigation]} loop>
        {
          heroData.length>0 && (
            heroData.map((element,index)=>{
              return (
                <SwiperSlide
                className=" bg-cover"
                style={{
                  backgroundImage: `url(${config.apiUrl}${element.attributes.Background_Image.data[0].attributes.url})`,
                
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-12 ps-md-5 pe-md-5 col-xxl-7 col-lg-8 col-md-8 col-sm-10">
                      <div className="hero-contents pe-lg-3">
                        <h1
                          className="fs-md wow fadeInLeft animated"
                          data-wow-duration="1.3s"
                        >
                          {element.attributes.HeroHeading}
                        </h1>
                        <p
                          className="pe-lg-5 wow fadeInLeft animated"
                          data-wow-duration="1.3s"
                          data-wow-delay=".4s"
                        >
                          {element.attributes.HeroSubHeading}
                        </p>
                        {/* <Link
                          to={`${element.attributes.First_Button_Link}`}
                          // href="contact.html"
                          className="theme-btn me-sm-4 wow fadeInLeft"
                          data-wow-duration="1.2s"
                          data-wow-delay=".8s"
                        >
                         {element.attributes.First_Button_Text}
                        </Link> */}
                        {/* <a
                          href="about.html"
                          className="plus-text-btn wow fadeInLeft"
                          data-wow-duration="1.2s"
                          data-wow-delay=".6s"
                        > */}
                          {/* <div className="icon">
                            <i className="fas fa-plus"></i>
                          </div> */}
                          {/* <div className="link-text">
                          </div>
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              )
            })
          )
        }
      </Swiper>
    </section>
  );
};

export default HeroOne;
