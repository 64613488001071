import videoBg from "../../assets/img/video_bg_1.jpg";
import imgBlock1 from "../../assets/img/home1/img-block1.jpg";
import tabImg1 from "../../assets/img/home1/tab-img.jpg";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import "react-modal-video/scss/modal-video.scss";
import ModalVideo from "react-modal-video";
import { getCompanyAboutOne } from "../../utils";
import { config } from "../../config";
import RequestDemo from "./PopupRequestDemo";

const AboutOne = ({ pt }) => {
  const [isOpen, setOpen] = useState(false);
  const [companyData, setCompanyData] = useState({});
  useEffect(()=>{
    getData()
  },[])
  const getData = async () => {
    const response = await getCompanyAboutOne()
    setCompanyData(response?.data[0]?.attributes)
  }
  return (
    <>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId={companyData?.video}
        onClose={() => setOpen(false)}
      />
      <section
        className={
          pt
            ? "about-section section-padding"
            : "about-section section-padding pt-0"
        }
        id="about-one"
      >
        <div className="container" style={{background:"#f5f5f5"}}>
          <div className="row margin-about">
            <div className="col-xl-6 col-lg-5 col-12">
              <div className="img-block-pop-video">
                <img
                  src={
                    `${config.apiUrl}${companyData?.image?.data?.attributes?.url}`
                  }
                  alt="busico"
                  width={"540"}
                  height={"620"}
                />
                <div
                  className="popup-video-block d-flex justify-content-center align-items-center bg-cover"
                  style={{
                    backgroundImage: `url(${"https://cdn.pixabay.com/photo/2015/05/28/14/38/ux-787980__340.jpg"})`,
                  }}
                >
                  <div className="video-play-btn">
                    <span
                      className="popup-video"
                      onClick={() => setOpen(true)}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="fas fa-play"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7 col-md-10 col-12">
              <div className="block-contents">
                <div className="section-title">
                  <span>About Company</span>
                  <h2>{companyData?.heading}</h2>
                </div>
                <p  dangerouslySetInnerHTML={{
                        __html: companyData?.content
                      }}>
                </p>
                <Link to="/contact"> <button className="btn bg-warning text-white p-3 rounded mt-3" >Contact DIRIDO</button></Link>
                <RequestDemo/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutOne;
