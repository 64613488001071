import { config } from "../config";
import axios from "axios";
const path = {
  baseUrl: config.mainUrl,
  assetsUrl: config.assetsUrl,
  apiUrl: config.apiUrl,
};

export const getPricing = async () => {
  const response = await axios
    .get(`${path.apiUrl}/api/pricings`, {
      Accept: "application.json",
      "Content-Type": "application/json",
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });

  return response;
};

export const getFAQs = async () => {
  const response = await axios
    .get(`${path.apiUrl}/api/faqs`, {
      Accept: "application.json",
      "Content-Type": "application/json",
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });

  return response;
};

export const getFAQsHeaderImage = async () => {
  const response = await axios
    .get(`${path.apiUrl}/api/images`, {
      Accept: "application.json",
      "Content-Type": "application/json",
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });

  return response;
};
export const getServices = async () => {
  const response = await axios
    .get(`${path.apiUrl}/api/services`, {
      Accept: "application.json",
      "Content-Type": "application/json",
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });

  return response;
};
export const getProjects = async () => {
  const response = await axios
    .get(`${path.apiUrl}/api/projects`, {
      Accept: "application.json",
      "Content-Type": "application/json",
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });

  return response;
};

export const getContacts = async () => {
  const response = await axios
    .get(`${path.apiUrl}/api/contacts`, {
      Accept: "application.json",
      "Content-Type": "application/json",
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });

  return response;
};
export const getTeamMember = async () => {
  const response = await axios
    .get(`${path.apiUrl}/api/team-members`, {
      Accept: "application.json",
      "Content-Type": "application/json",
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });

  return response;
};
export const getTestimonial = async () => {
  const response = await axios
    .get(`${path.apiUrl}/api/testimonials`, {
      Accept: "application.json",
      "Content-Type": "application/json",
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });

  return response;
};

export const getStats = async () => {
  const response = await axios
    .get(`${path.apiUrl}/api/stats`, {
      Accept: "application.json",
      "Content-Type": "application/json",
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });

  return response;
};
export const getFeatures = async () => {
  const response = await axios
    .get(`${path.apiUrl}/api/features`, {
      Accept: "application.json",
      "Content-Type": "application/json",
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });

  return response;
};

export const postContactDetail = async (data) => {
  const response = await axios
    .post(`${path.apiUrl}/api/get-in-touches`, data, {headers:{
      Accept: "application.json",
      "Content-Type": "application/json",
    }})
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });

  return response;
};

export const getHomeHero = async () => {
  const response = await axios
    .get(`${path.apiUrl}/api/home-heroes?populate=*`, {
      Accept: "application.json",
      "Content-Type": "application/json",
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });

  return response;
};

export const getHomeNext = async () => {
  const response = await axios
    .get(`${path.apiUrl}/api/home-nexts?populate=*`, {
      Accept: "application.json",
      "Content-Type": "application/json",
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });

  return response;
};

export const getCompanyAboutOne = async () => {
  const response = await axios
    .get(`${path.apiUrl}/api/about-company-ones?populate=*`, {
      Accept: "application.json",
      "Content-Type": "application/json",
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });

  return response;
};

export const getStaticPage = async () => {
  const response = await axios
    .get(`${path.apiUrl}/api/static-pages?populate=*`, {
      Accept: "application.json",
      "Content-Type": "application/json",
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });

  return response;
};

export const getSocial = async () => {
  const response = await axios
    .get(`${path.apiUrl}/api/socials?populate=*`, {
      Accept: "application.json",
      "Content-Type": "application/json",
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });

  return response;
};


