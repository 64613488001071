import { Swiper, SwiperSlide } from "swiper/react";
import React, { useEffect, useState } from "react";

import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import testimonialOneData from "./testimonialOneData";
import { getTestimonial } from "../../utils";

const TestimonialOne = () => {
  const [testmonialData, setTestmonialData] = useState("");

  useEffect(() => {
    const fetchTestimonial = async () => {
      const res = await getTestimonial();
      setTestmonialData(res.data);
    };
    fetchTestimonial();
  }, []);
  return (
    <section className="testimonial-carousel-wrapper section-padding pb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3 text-center col-12">
            <div className="block-contents">
              <div className="section-title">
                <span>04. Testimonials</span>
                <h2>What People say about us </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-12">
            <div className="testimonial-carousel-active">
              <Swiper navigation={true} modules={[Navigation]} loop>
                {testmonialData.length > 0 &&
                  testmonialData.map((data) => (
                    <SwiperSlide
                      className="single-testimonial-item"
                      key={data.id}
                    >
                      <p>{data.attributes.content}</p>
                      <div className="client-info">
                        <h5>{data.attributes.title}</h5>
                        <span>{data.attributes.name}</span>
                      </div>
                      <div
                        className="client-img bg-cover bg-center"
                        style={{ backgroundImage: `url(${data.img})` }}
                      ></div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialOne;
