import React from "react";
import FooterTwo from "../Components/Footer/FooterTwo";
import HeaderOne from "../Components/Header/HeaderOne";
import bannerBg from "../assets/img/page-banner.jpg";
import PageBanner from "../Components/PageBanner";
import BrandCarouselThree from "../Components/Brands/BrandCarouselThree";
import FaqOne from "../Components/Faq/FaqOne";
import CtaOne from "../Components/Cta/CtaOne";
import { getFAQsHeaderImage } from "../utils/index";
import { config } from "../config";

const FaqPage = () => {
  // const [bannerBg, setBannerBg] = useState("");

  // useEffect(() => {
  //   const fetchImage = async () => {
  //     const res = await getFAQsHeaderImage();
  //     setBannerBg(res.data);
  //   };
  //   fetchImage();
  // }, []);

  // console.log(bannerBg?.[0].attr);

  return (
    <>
      <PageBanner
        title="FAQ Ask"
        bannerBg={
          "https://img.freepik.com/premium-photo/raised-up-hands-arms-large-group-seminar-class-room-agree-with-speaker-conference-seminar-meeting-room_29360-140.jpg?w=740 "
        }
        currentPage="faq"
      />
      <FaqOne />
      <CtaOne />
      {/* <BrandCarouselThree /> */}
    </>
  );
};

export default FaqPage;
