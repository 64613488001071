import { useState } from "react";
import { Link } from "react-router-dom";
import mainLogo from "../../assets/img/logo.png";
import MobileMenu from "./MobileMenu";
import OffsetMenu from "./OffsetMenu";
import GoogleTranslate from "./GoogleTranslate";

const HeaderOne = () => {
  const [search, setSearch] = useState(true);
  const [offset, setOffset] = useState(true);
  const [mobileMenu, setMobileMenu] = useState(true);

  const handleSearch = () => {
    setSearch(!search);
  };

  // const handleOffset = (e) => {
  //   e.preventDefault();
  //   setOffset(!offset);
  // };

  const handleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };
  return (
    <>
      {/* <OffsetMenu offset={offset} handleOffset={handleOffset} /> */}
      <header className="header-wrap header-1 sticky-top" style={{padding:"10px 0px"}}>
        <div className="container-fluid d-flex justify-content-between align-items-center">
          <div className="logo ">
            <Link
              to="/"
              style={{
                color: "#FF5E14",
                fontWeight: "800",
                fontSize: "50px",
                lineHeight: "0.6",
                fontFamily: '"Exo 2", sans-serif',
                letterSpacing: "5px",
              }}
            >
              DIRIDO
            </Link>
          </div>
          <div className="header-right-area d-flex">
            <div className="main-menu d-none d-xl-block">
              <ul style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                <li>
                  <Link k to="/">
                    Home
                  </Link>
                </li>
                {/* <li>
                  <Link to="/about">about us</Link>
                </li> */}
                {/* <li>
                  <Link to="/services">Products</Link>
                </li>
                <li>
                  <Link to="/Team">Teams</Link>
                </li>

                <li>
                  <Link to="/pricing">Pricing</Link>
                </li> */}
                <li>
                  <Link to="/solutions">Platforms</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
                <li>
                  <Link to="/investor">Investor Relation</Link>
                </li>
                <li>
                  <GoogleTranslate />
                </li>
              </ul>
            </div>
            <div className="header-right-elements d-flex align-items-center justify-content-between">
              <div className="d-inline-block ms-4 d-xl-none">
                <div className="mobile-nav-wrap">
                  <div id="hamburger" onClick={handleMobileMenu}>
                    <i className="fal fa-bars"></i>
                  </div>
                  <MobileMenu
                    mobileMenu={mobileMenu}
                    handleMobileMenu={handleMobileMenu}
                  />
                </div>
                <div className="overlay"></div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default HeaderOne;
