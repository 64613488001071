import { Link } from "react-router-dom";
import servicesOneData from "./servicesOneData";
import { getServices } from "../../utils";
import { useState } from "react";
import { useEffect } from "react";
import { config } from "../../config";

const ServicesOne = () => {
  const [sevicesData, setSevicesData] = useState("");

  useEffect(() => {
    const fetchServices = async () => {
      const res = await getServices();
      setSevicesData(res.data);
    };
    fetchServices();
  }, []);
  return (
    <section className="our-service-wrapper section-padding bg-gray" style={{background:"#f5f5f5"}}>
      <div className="container">
        <div className="row mtm-30 margin-service">
          <div className="col-md-6 col-12 col-lg-4 mt-30">
            <div className="block-contents">
              <div className="section-title">
                <span>02. Featured Service</span>
                <h2>What We Offer You</h2>
              </div>
              <p>
                Our promise as a contractor is to build strong community value
                into every projects
              </p>
            </div>
          </div>
          {sevicesData.length > 0 &&
            sevicesData.map((data) => (
              <div className="col-md-6 col-12 col-lg-4 mt-30" key={data.id}>
                <div className="single-service-box style-1">
                  <div
                    className="service-bg bg-cover"
                    // style={{ backgroundImage: `url(${data.bg})` }}
                    style={{
                      backgroundImage: `url(${"https://cdn.pixabay.com/photo/2015/01/09/11/08/startup-594090__340.jpg"})`,
                    }}
                  ></div>
                  <div className="icon">
                    <i className={"flaticon-blueprint"}></i>
                    {/* <img
                      className="flaticon w-25 h-25"
                      src={config.apiUrl + data.attributes.icon_url}
                    /> */}
                  </div>
                  <div className="contents">
                    <h4>
                      <Link to="/servicesDetails">
                        {data.attributes.heading}
                      </Link>
                    </h4>
                    <p>{data.attributes.content}</p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesOne;
