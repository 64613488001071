import HeaderOne from "../Components/Header/HeaderOne";
import WorkProcess from "../Components/Work/WorkProcess";

const Platform = () => {
    return (
        <>
            <WorkProcess />
        </>
    )
}

export default Platform;