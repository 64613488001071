import React, { useEffect, useState } from "react";
import FooterTwo from "../Components/Footer/FooterTwo";
import HeaderOne from "../Components/Header/HeaderOne";
import bannerBg from "../assets/img/page-banner.jpg";
import PageBanner from "../Components/PageBanner";
import BrandCarouselThree from "../Components/Brands/BrandCarouselThree";
import FaqOne from "../Components/Faq/FaqOne";
import CtaOne from "../Components/Cta/CtaOne";
import { getFAQsHeaderImage, getStaticPage } from "../utils/index";
import { config } from "../config";
import DangerousHTML from 'react-dangerous-html';
import parse from 'html-react-parser';

const Terms = () => {
  const [termsCondition, setTermsCondition] = useState(`<h1></h1>`)
  useEffect(() => {
    window.scrollTo(0, 0)
    getData()
  }, [])
  const getData = async () => {
    const data = await getStaticPage()
    const findIndex = data.data.findIndex(el => el.attributes.key == "term-condition")
    setTermsCondition(data.data[findIndex].attributes.value)
  }
  return (
    <>
      <PageBanner
        title="Terms & Conditions"
        bannerBg={
          "https://img.freepik.com/premium-photo/raised-up-hands-arms-large-group-seminar-class-room-agree-with-speaker-conference-seminar-meeting-room_29360-140.jpg?w=740 "
        }
        currentPage="terms & Conditions"
      />
      <div style={{ marginTop: "30px", padding: "25px" }}>
        <h1 style={{ textAlign: "center" }}>Terms & Conditions</h1>
        {
          parse(termsCondition)
        }
      </div>
    </>
  );
};

export default Terms;
