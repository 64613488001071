import { useEffect, useState } from "react"
import { getSocial } from "../../utils"

const HeroSocials = () => {
    const [social,setSocial] = useState({
        facebook:"",
        linkedin:"",
        instagram:"",
        twitter:""
    })
    useEffect(()=>{
        getData()
    },[])
    const getData = async () => {
        const data = await getSocial()
        const facebookIndex = data.data.findIndex(el=>el.attributes.key=="facebook")
        const linkedinIndex = data.data.findIndex(el=>el.attributes.key=="linkedIn")
        const instagramIndex = data.data.findIndex(el=>el.attributes.key=="instagram")
        const twitterIndex = data.data.findIndex(el=>el.attributes.key=="twitter")
        console.log(data.data[linkedinIndex])
        const socialLinks = {
            facebook:data.data[facebookIndex].attributes.Value,
            linkedin:data.data[linkedinIndex].attributes.Value,
            instagram:data.data[instagramIndex].attributes.Value,
            twitter:data.data[twitterIndex].attributes.Value,
        }
        setSocial(socialLinks)
    }
    return (
        <>
        <div className="hero-social-elements social-links ">
        <div className="flp-text">
            <p>Follow Us</p>
        </div>
        <div className="long-arrow"></div>
        <div className="social-link">
            <a href={social?.twitter} target="_blank"><i className="fab fa-twitter"></i></a>
            <a href={social?.instagram} target="_blank"><i className="fab fa-instagram"></i></a>
            <a href={social?.facebook} target="_blank"><i className="fab fa-facebook-f"></i></a>
            <a href={social?.linkedin} target="_blank"><i className="fab fa-linkedin-in"></i></a>
        </div>
    </div>
        </>
    )
}

export default HeroSocials