import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getContacts, postContactDetail } from "../../utils";
import Form from 'react-bootstrap/Form';

const ContactPageContents = () => {
  const [contactData, setContactData] = useState("");
  const [details, setDetails] = useState({
    organization_name: "",
    organization_type: "",
    contact_person: "",
    salutation: "",
    position: "",
    street_address: "",
    street_address_1: "",
    city: "",
    country: "",
    zip_code: "",
    country: "",
    phone: "",
    email: "",
    website: "",
    purpose: "",
    contact_means: "",
    contact_time: "",
    networks: ""
  })
  useEffect(() => {
    const fetchContact = async () => {
      const res = await getContacts();
      setContactData(res.data);
    };
    fetchContact();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault()
    const response = await postContactDetail({ data: details })
    alert("Details Submitted")
    setDetails({
      organization_name: "",
      organization_type: "",
      contact_person: "",
      salutation: "",
      position: "",
      street_address: "",
      street_address_1: "",
      city: "",
      country: "",
      zip_code: "",
      country: "",
      phone: "",
      email: "",
      website: "",
      purpose: "",
      contact_means: "",
      contact_time: ""
    })
  }
  const handleChange = (e) => {
    let data = { ...details };
    data[e.target.name] = e.target.value
    setDetails(data)
  }
  return (
    <div className="contact-us-wrapper section-padding">
      <div className="container">
        <div className="row eq-height">
          <div className="col-lg-8 col-12">
            <div className="contact-form" style={{ background: "#EDEDED" }}>
              <h2>Get in Touch</h2>
              <form
                className="row"
                onSubmit={(e) => { handleSubmit(e) }}
              >
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input type="text" name="organization_name" placeholder="Organization Name" value={details.organization_name} onChange={(e) => handleChange(e)} />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div>
                    <Form.Select size="lg" name="organization_type" value={details.organization_type} onChange={(e) => { handleChange(e) }} aria-label="Default select example" style={{ padding: "20px 30px", border: "none", backgroundColor: "#F8F9FC", fontSize: "17px", fontWeight: "400" }}>
                      <option value="">Organization Type</option>
                      <option value="Governmental">Governmental</option>
                      <option value="Authority">Authority</option>
                      <option value="Private">Private Company</option>
                      <option value="NGO">NGO</option>
                    </Form.Select>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div>
                    <Form.Select size="lg" name="salutation" value={details.salutation} onChange={(e) => { handleChange(e) }} aria-label="Default select example" style={{ padding: "20px 30px", border: "none", backgroundColor: "#F8F9FC", fontSize: "17px", fontWeight: "400" }}>
                      <option value="">Salutation</option>
                      <option value="mr">Mr</option>
                      <option value="mrs">Mrs</option>
                      <option value="miss">Miss</option>
                      <option value="ms">Ms</option>
                    </Form.Select>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input type="text" name="contact_person" placeholder="Contact Person" value={details.contact_person} onChange={(e) => handleChange(e)} />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input type="text" name="position" placeholder="Position" value={details.position} onChange={(e) => handleChange(e)} />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input type="text" name="website" placeholder="Business Website" value={details.website} onChange={(e) => handleChange(e)} />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input type="text" name="street_address" placeholder="Address Line 1" value={details.street_address} onChange={(e) => handleChange(e)} />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input type="text" name="street_address_1" placeholder="Address Line 2" value={details.street_address_1} onChange={(e) => handleChange(e)} />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input type="text" name="city" placeholder="City" value={details.city} onChange={(e) => handleChange(e)} />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input type="text" name="country" placeholder="Country" value={details.country} onChange={(e) => handleChange(e)} />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input type="text" name="zip_code" placeholder="Zip Code" value={details.zip_code} onChange={(e) => handleChange(e)} />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input type="text" name="phone" placeholder="Phone" value={details.phone} onChange={(e) => handleChange(e)} />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input type="text" name="email" placeholder="Business Email" value={details.email} onChange={(e) => handleChange(e)} />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                  <Form.Select size="lg" name="purpose" value={details.purpose} onChange={(e) => { handleChange(e) }} aria-label="Default select example" style={{ padding: "20px 30px", border: "none", backgroundColor: "#F8F9FC", fontSize: "17px", fontWeight: "400" }}>
                      <option value="">Purpose</option>
                      <option value="clients">Clients Related</option>
                      <option value="Service">Service Related</option>
                      <option value="Social">Social</option>
                    </Form.Select>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div>
                    <Form.Select size="lg" name="contact_means" value={details.contact_means} onChange={(e) => { handleChange(e) }} aria-label="Default select example" style={{ padding: "20px 30px", border: "none", backgroundColor: "#F8F9FC", fontSize: "17px", fontWeight: "400" }}>
                      <option value="">How Should We Contact You?</option>
                      <option value="phone">Phone</option>
                      <option value="Email">Email</option>
                      <option value="Others">Others</option>
                    </Form.Select>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div>
                    <Form.Select size="lg" name="contact_time" value={details.contact_time} onChange={(e) => { handleChange(e) }} aria-label="Default select example" style={{ padding: "20px 30px", border: "none", backgroundColor: "#F8F9FC", fontSize: "17px", fontWeight: "400" }}>
                      <option value="">When Will We Connect you?</option>
                      <option value="8-5 PM">8-5 PM</option>
                      <option value="Others">Others</option>
                    </Form.Select>
                  </div>
                </div>
                <div className="col-md-6 col-12" style={{ marginTop: "25px" }}>
                  <div>
                    <Form.Select size="lg" name="networks" value={details.networks} onChange={(e) => { handleChange(e) }} aria-label="Default select example" style={{ padding: "20px 30px", border: "none", backgroundColor: "#F8F9FC", fontSize: "17px", fontWeight: "400" }}>
                      <option value="">Networks?</option>
                      <option value="Legal">Legal</option>
                      <option value="Regulations">Regulations</option>
                      <option value="Copyrights & Trademarks">Copyrights & Trademarks</option>
                      <option value="Copyrights & Trademarks">General Inquiry</option>
                    </Form.Select>
                  </div>
                </div>
                <div className="col-md-12 col-12" style={{ marginTop: "25px" }}>
                  <button
                    className="submit-btn"
                    type="submit"
                  >
                    Submit Now
                  </button>
                </div>
              </form>
              <span className="form-message" />
            </div>
          </div>
          <div className="col-lg-4  col-12 ">
            <div className="contact-us-sidebar mt-5 mt-lg-0">
              <div className="contact-info">
                <h2>CONTACT INFO</h2>
                {
                  contactData?.[0]?.attributes?.label.length > 0 ?
                    <div className="single-info">
                      <div className="icon">
                        <i className="flaticon-email" />
                      </div>
                      <div className="text">
                        <span> {contactData?.[0]?.attributes?.label}</span>
                        <p>{contactData?.[0]?.attributes?.info}</p>
                      </div>
                    </div>
                    :
                    <></>
                }
                {
                  contactData?.[1]?.attributes?.label.length > 0 ?
                    <div className="single-info d-flex">
                      <div className="icon">
                        <i className="flaticon-pin" />
                      </div>
                      <div className="text">
                        <span>{contactData?.[1]?.attributes?.label}</span>
                        <p>{contactData?.[1]?.attributes?.info}</p>
                      </div>
                    </div>
                    :
                    <></>
                }
                {
                  contactData?.[2]?.attributes?.label.length > 0 ?
                    <div className="single-info">
                      <div className="icon">
                        <i className="flaticon-phone-call-1" />
                      </div>
                      <div className="text">
                        <span>{contactData?.[2]?.attributes?.label}</span>
                        <p>{contactData?.[2]?.attributes?.info}</p>
                      </div>
                    </div>
                    :
                    <></>
                }
              </div>
              {/* <div className="live-chat">
                <h2>Live Chat</h2>
                <p>You can contact us on chat and explain your problems</p>
                <Link to="/about">
                  <i className="flaticon-chatting" /> Live Chat to Executive
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPageContents;
