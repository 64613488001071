import React from "react";
import FooterTwo from "../Components/Footer/FooterTwo";
import HeaderOne from "../Components/Header/HeaderOne";
// import bannerBg from "../assets/img/page-banner.jpg";
import PageBanner from '../Components/PageBanner';
import Details from "../Components/Services/Details";
const bannerBg =
  "https://images.unsplash.com/photo-1512403754473-27835f7b9984?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fGJ1c2luZXNzJTIwYmFja2dyb3VuZHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=400&q=60";
const ServiceDetails = () => {
  return (
    <>
      <PageBanner
        title="INTERIOR DESIGN"
        bannerBg={bannerBg}
        currentPage="SERVICE DETAILS"
      />
      <Details />
    </>
  );
};

export default ServiceDetails;
